body {
  background: white;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.48);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.skulist__page {
  margin-top: 40px;
  .skulist__page--header {
    display: flex;
    align-items: baseline;
    .select {
      margin-left: auto;
      margin-right: 30px;
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('../../assets/images/icons/png/arrow-drop-down.png');
        background-position: right center;
        background-repeat: no-repeat;
        border: 0;
        font-family: 'Proxima Nova';
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.79px;
        line-height: 13px;
        padding: 8px 20px 8px 10px;
        &:focus {
          outline: none;
        }
      }
    }

    h1 {
      color: #000000;
      font-family: 'Minion Pro';
      font-size: 32px;
      letter-spacing: 2px;
      line-height: 46px;
      margin-right: 15px;
    }
    small {
      color: #000000;
      font-family: 'Proxima Nova';
      font-size: 14px;
      line-height: 17px;
    }
    .searchSku {
      margin-left: 200px;
    }
  }
}

.bg__gray {
  background-color: #ececec;
  margin: 0 -35px;
  padding: 35px;
  width: calc(100% + 70px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .pageNum {
    margin-left: 10px;
    cursor: pointer;
  }
  .lastPageNum {
    margin-right: 10px;
    cursor: pointer;
  }
  .selected {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
}

.skuTable {
  border-collapse: collapse;
  th,
  td {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 14px;
    color: #2a2b30;
    padding: 21px 12px 22px 12px;
    img {
      max-height: 70px;
    }
  }
  thead {
    background-color: #ececec;
    tr {
      th {
        &:last-child {
          display: none;
        }
        .headerRow {
          display: flex;
          align-items: center;
          border-color: #ececec;
          text-align: left;
          text-transform: uppercase;
          color: #000000;
          font-family: 'Proxima Nova';
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 0.79px;
          line-height: 13px;

          white-space: pre;

          .arrows {
            .disabled {
              opacity: 0.5;
            }

            .sort-up {
              margin-bottom: -5px !important;
            }

            .sort-down {
              margin-top: -8px !important;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        &:first-child {
          min-width: 550px;
        }
        &:last-child {
          display: none;
        }
        border-bottom: 1px solid #c7c7c7;
      }
    }
  }

  .deleteBtn {
    background: none;
    border: none;

    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:before {
      background-image: url('../../assets/images/icons/png/bin.png');
      background-repeat: no-repeat;
      background-position: center center;
      width: 16px;
      height: 16px;
      content: '';
      display: inline-block;
      background-size: cover;
    }
  }
}

.info__sidebar {
  overflow: auto;
  padding: 70px 35px 0 35px;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 593px;
  left: -593px;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  top: 0;
  z-index: 900;

  &::-webkit-scrollbar {
    display: none;
  }

  &.closed {
    -webkit-animation: slide-closed 1s backwards;
    -webkit-animation-delay: 1s;
    animation: slide-closed 1s backwards;
    animation-delay: 1s;
  }

  &.opened {
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
  }
  .close-button {
    position: absolute;
    top: 20px;
    left: 15px;
    border: 0;
    background-color: transparent;
    background-image: url('../../assets/images/icons/png/close.png');
    background-size: cover;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .sidebar__subMenuWrapper--material,
  .sidebar__subMenuWrapper--size {
    border: 0;
  }
  p,
  ul li {
    color: #000000;
    font-family: 'Proxima Nova';
    font-size: 12px;
    line-height: 18px;
  }
  .description {
    margin-top: 30px;
    display: block;
    width: 100%;
  }
}

.frm__skulist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  label {
    color: #000000;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-transform: uppercase;
  }
  .sizeInput {
    width: 10%;
  }
  .upload-button-wrapper {
    position: relative;
    input[type='file'] {
      position: absolute;
      top: 0;
      width: 18px;
      height: 18px;
      opacity: 0;
    }
  }
  .form-group {
    margin-bottom: 15px;
    flex-basis: 100%;

    input,
    textarea {
      display: block;
      border: 0;
      border-bottom: 1px solid #c7c7c7;
      color: #000000;
      font-family: 'Proxima Nova';
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      padding: 10px 0;
      width: 100%;
      background: transparent;
    }

    input[type='checkbox'] {
      width: 7%;
    }
  }

  .btn {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000000;
    font-family: 'Proxima Nova';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 100%;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    padding: 10px 20px;
    border: 1px solid #000000;
    background: transparent;
    position: relative;
    transition: all ease 300ms;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15px;
    }
    &:hover {
      background: #000000;
      color: #ffffff;
    }
    &.btn.active {
      border-bottom: 1px solid white;
      padding: 5px 0;
    }
    &.btn.active:hover {
      padding: 10px 15px;
    }
  }
}

.icon-button {
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 18px;
  height: 18px;
  margin-bottom: 10px;
  &.remove-button {
    background-image: url('../../assets/images/icons/png/bin.png');
  }
  &.upload-button {
    background-image: url('../../assets/images/icons/png/upload.png');
  }
}

.half__container {
  flex-basis: calc(50% - 10px);
  width: calc(50% - 10px);
  &:nth-child(2n) {
    margin-left: 20px;
  }

  label {
    color: #000000;
    font-family: 'Proxima Nova';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-transform: uppercase;
  }

  .form-group {
    .fileUpload {
      display: flex;
      margin-top: 10px;
    }

    .image {
      display: flex;
      margin-top: 10px;
      img {
        margin: 0 10px 0 0;
      }
    }
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-closed {
  100% {
    left: -593px;
  }
}

@keyframes slide-closed {
  100% {
    left: -593px;
  }
}
