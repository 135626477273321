.controllerWrapper {
  position: absolute;
  right: 40px;
  bottom: 40px;

  .btnController {
    border: 1px solid #000000;
    height: 36px;
    width: 36px;
    cursor: pointer;
    margin: 5px;

    &.active {
      background: #ffffff;
    }

    &:focus {
      outline: none;
    }

    &.item1 {
      background: url('../../assets/images/icons/png/tag.svg') no-repeat center
        #000000;

      &.selected {
        background: url('../../assets/images/icons/png/tag-black.svg') no-repeat
          center;
        background-color: #ffffff;
      }
    }

    &.item2 {
      background: url('../../assets/images/icons/png/pdf.svg') no-repeat center
        #000000;
    }

    &.item2:after {
      background: url('../../assets/images/icons/png/pdf.svg') no-repeat center
        #000000;
    }

    &.item3 {
      background: url('../../assets/images/icons/png/measure.svg') no-repeat
        center #000000;

      &.selected {
        background: url('../../assets/images/icons/png/measure-black.svg')
          no-repeat center;
        background-color: #ffffff;
      }
    }

    &.item4 {
      background: url('../../assets/images/icons/png/zoomout.svg') no-repeat
        center #000000;
    }

    &.item5 {
      background: url('../../assets/images/icons/png/zoomin.svg') no-repeat
        center #000000;
    }

    &.item6 {
      background: url('../../assets/images/icons/png/undo.svg') no-repeat center
        #000000;
    }

    &.item7 {
      background: url('../../assets/images/icons/png/redo.svg') no-repeat center
        #000000;
    }

    &.item8 {
      background: url('../../assets/images/icons/png/csv.svg') no-repeat center
        #000000;
    }

    &.item9 {
      &:before {
        @include background-image(
          '../../assets/images/icons/png/360-rotate-light.png',
          22,
          22
        );
      }
      &.active:before {
        @include background-image(
          '../../assets/images/icons/png/360-rotate.png',
          22,
          22
        );
      }
    }
    &.item10 {
      &:before {
        @include background-image(
          '../../assets/images/icons/png/binocular-light.png',
          22,
          22
        );
      }
      &.active:before {
        @include background-image(
          '../../assets/images/icons/png/binocular.png',
          22,
          22
        );
      }
    }
  }
}

.room-label {
  position: absolute;
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 12px;
  opacity: 0.5;
  background-color: #000000;
  text-align: center;
  padding: 2px;
}
