#viewChanges {
  position: absolute;
  right: 40px;
  top: 70px;

  .viewSelector {
    box-sizing: border-box;
    height: 37px;
    width: 37px;
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    &.walking-man {
      background: url('../../assets/images/icons/png/single-neutral.svg')
        no-repeat center;
      background-color: #000000;

      &.selected {
        background: url('../../assets/images/icons/png/single-neutral-black.svg')
          no-repeat center;
        background-color: #ffffff;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.exitDialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .exitDialog {
    width: 350px;
    height: 50px;
    background-color: #000000;
    opacity: 0.5;
    color: #ffffff;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.14px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: 100px;
    margin: auto;
    padding: 5px;
    max-width: 50%;
    border: #ffffff 1px solid;

    .escapeKey {
      border: #ffffff 1px solid;
      margin: 0 5px;
      height: 40px;
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
