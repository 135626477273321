.mass-loader-btn {
  position: absolute;
  right: 40px;
  bottom: 400px;
  color: #ffffff;
  font-family: $font-proxima-nova-regular;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 100%;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  background: #000000;
  transition: all ease 300ms;
  margin-left: 20px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15px;
  }
  &:hover {
    background: #ffffff;
    color: #000000;
  }
  &.btn.active {
    border-bottom: 1px solid white;
    padding: 5px 0;
  }
  &.btn.active:hover {
    padding: 10px 15px;
  }
}
