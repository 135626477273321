.room_designer_container {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  display: flex;
  align-items: center;

  .evolutionCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    &:hover {
      cursor: url('/icons/paint-bucket.png');
    }
  }
}

.furniture-dimension {
  position: absolute;

  background-color: #000000;
  color: #ffffff;
  font-family: 'Proxima Nova';
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  padding: 2px;
}

.iconContainer {
  position: absolute;
  .icon {
    width: 2em;
    height: 2em;
    margin: 0.5em;
    border-radius: 50%;
    background-color: #cbcbcb;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;

    &:hover {
      cursor: pointer;
    }
  }

  .copy {
    margin-top: 5px;
    margin-left: 6px;
    color: #383838;
  }
}
.dg {
  &.ac {
    top: 245px !important;
  }
}
