// CORE ELEMENTS
$black: #2a2b30;
$grayscale1: #48494d;
$grayscale2: #66686b;
$grayscale3: #a3a3a5;
$grayscale4: #c1c2c2;
$grayscale5: #dfe0e0;
$grayscale6: #f3f4f3;

$green1: #80e0a7;
$green2: #23a56b;
$green3: #3ac286;
$green4: #9cfdd2;
$green5: #cafee6;

$blue1: #7ba2d5;
$blue2: #5474a0;
$blue3: #abccf8;

$brown1: #b49a81;
$brown2: #7e6b58;
$brown3: #daccc0;

$turquoise1: #1d3c34;
$turquoise2: #48605f;
$turquoise3: #6a888a;
$turquoise4: #96b9bb;
$turquoise5: #c5eaeb;

$slateGray1: #546164;
$slateGray2: #353e40;
$slateGray3: #92a1a4;

$darkOliver1: #495649;
$darkOliver2: #2b372c;
$darkOliver3: #a3aba4;

html,
body {
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-repeat: no-repeat;
  font-family: $font-proxima-nova-regular;
  margin: 0;
  padding: 0;
}

html,
body,
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
}

.dark__theme {
  background-color: $slateGray2;
  .text__block {
    h1 {
      color: #f3f4f3;
    }
    p {
      color: #f3f4f3;
    }
  }
  .selector {
    span {
      color: #f3f4f3;
      &:after {
        // @include background-image("../assets/images/icons/arrow-right-white.svg", 12, 12);
      }
    }
  }
  .products {
    &__item {
      span {
        color: #f3f4f3;
      }
      small {
        color: $grayscale6;
        opacity: 0.5;
      }
    }
  }
}

.container {
  width: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;

  @media (max-width: 1280px) {
    padding: 0 20px;
  }
  &__small {
    max-width: 678px;
    margin: 100px auto;
  }
  // @media (max-width: 1440px) {
  //   padding: 0 85px;
  // }
  // @media (max-width: 992px) {
  //   padding: 0 30px;
  // }
  // @media (max-width: 768px) {
  //   padding: 0 15px;
  // }
}

.up-link {
  text-decoration: none !important;
  &:after {
    // @include background-image("../../assets/images/icons/arrow-right-white.svg", 13, 13);
    transform: rotate(-90deg);
    margin-left: 10px;
  }
  @media (max-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.image-featured-wrapper {
  @media (max-width: 992px) {
  }
}

//styled-checkbox
.radio {
  position: absolute !important;
  opacity: 0;
  margin-left: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 99;
  // &:hover {
  //   + label:before {
  //     border: 3px solid $black;
  //   }
  // }
  + label:before {
    content: '';
    margin-bottom: -2px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: base;
    width: 16px;
    height: 16px;
    border: 1px solid #7e6b58;
    border-radius: 50%;
  }
  &:checked {
    + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      width: 2px;
      height: 2px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    + label:before {
      background-color: #7e6b58;
      border: 1px solid #7e6b58;
    }
  }
}

.frm__generic {
  padding-bottom: 20px;
  text-align: center;
  a {
    margin: 0 auto;
    color: #ffffff;
    font-family: $font-proxima-nova-regular;
    font-size: 12px;
    text-decoration: underline;
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  input {
    border: 1px solid #ffffff;
    color: #ffffff;
    font-family: $font-proxima-nova-regular;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 100%;
    margin-bottom: 15px;
    max-width: 377px;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid #ffffff;
    background: transparent;
    position: relative;
    transition: all ease 300ms;
    &::-webkit-input-placeholder {
      /* Edge */
      color: white;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    &::placeholder {
      color: white;
    }
  }
  .btn {
    &.btnSubmitModal {
      margin-top: 5px;
      background-color: #ffffff;
      text-align: center;
      color: $black;
      height: 32px;
      margin: 5px auto;
      max-width: 83px;
      font-family: $font-proxima-nova-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 100%;
      text-transform: uppercase;
    }
    &.btnNoMargin {
      margin: 5px 10px !important;
    }
  }
  .error {
    color: red;
    margin: 10px auto;
    max-width: 377px;
    font-family: $font-proxima-nova-regular;
    width: 100%;
  }
  .instructions {
    color: white;
    text-align: center;
    margin-bottom: 10px;
    font-family: $font-proxima-nova-regular;
  }
  .forgotPassword:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .buttonRow {
    display: flex;
    justify-content: center;
  }
}
