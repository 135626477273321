#selection_ui {
  position: absolute;

  display: flexbox;
  flex-direction: column;

  .icon {
    width: 2em;
    height: 2em;
    margin: 0.5em;
    border-radius: 50%;
    background-color: #cbcbcb;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;

    &:hover {
      cursor: pointer;
    }
  }

  .copy {
    margin-top: 5px;
    margin-left: 7px;
    color: #383838;
  }

  .info {
    background-image: url('../../assets/images/icons/png/streamline-icon-information-circle@40x40.png');
  }

  .delete {
    background-image: url('../../assets/images/icons/png/streamline-icon-bin@40x40.png');
  }

  .measure {
    background-image: url('../../assets/images/icons/png/streamline-icon-measure-ruler-corner@40x40.png');
  }

  .center {
    margin-top: 5px;
    margin-left: 6px;
    color: #383838;
    transform: rotate(90deg);
  }

  .centerVertical {
    margin-top: 6px;
    margin-left: 6px;
    color: #383838;
  }

  .fineTune {
    background-image: url('../../assets/images/icons/png/rotate-forward.png');

    &.active {
      background: url('../../assets/images/icons/png/redo.svg') no-repeat center
        #000000;
      background-size: 85%;
    }
  }

  .configure {
    background-image: url('../../assets/images/icons/png/rotate-back.png');
  }

  .dPad {
    background-image: url('../../assets/images/icons/png/directional-pad.png');
  }
}
