body {
  background: url('../assets/images/bg.svg') repeat #ffffff;
  background-size: 20px 20px;
}

#root {
  width: 100%;
}

.canvasWrapper {
  height: calc(100vh - 50px);
  position: relative;
  display: flex;
  align-items: center;

  &.collapse {
    float: right;
    width: calc(100vw - 370px);
    justify-content: center;
  }

  .infoWrapper {
    position: absolute;
    left: 100px;
    bottom: 20px;
    width: 248px;
    background-color: #ececec;
    padding: 15px;
    .info {
      color: #000000;
      font-family: 'Minion Pro';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
      display: block;
    }
    .color,
    .size {
      color: #000000;
      font-family: 'Proxima Nova';
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.79px;
      line-height: 13px;
      text-transform: uppercase;
      display: block;
      margin-top: 5px;
    }
  }
}

.unselectable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.unselectable-all {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.preview-container {
  position: relative;
  z-index: 1;
}

.preview-image {
  position: absolute;
  z-index: 2;
}

.overlay_text {
  position: relative;
  top: 0px;
  z-index: 3;
  height: 120px;
}

.float {
  position: fixed;
  width: 80px;
  height: 30px;
  bottom: 40px;
  left: 70px;
  background-color: #000000;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  padding-top: 2px;
}

.helpIcon {
  margin-left: -45px;
}

.helpText {
  position: absolute;
  margin-left: 2px;
  margin-top: 3px;
  font-family: 'Proxima Nova';
}
