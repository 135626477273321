.sidebar {
  height: calc(100vh - 50px);
  width: auto;
  position: relative;
  float: left;
  z-index: 2000;

  &__menuWrapper {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    &--menu {
      width: 50px;
      height: 100%;
      background: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item {
        color: #ffffff;
        height: 30px;
        width: 30px;
        position: relative;
        cursor: pointer;
        background: none;
        border: none;
        padding: unset;
        &:focus {
          outline: none;
        }

        &:after {
          display: none;
          content: '';
          background: #000000;
          color: #ffffff;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 11px;
          padding: 4px 7px;
          position: absolute;
          line-height: 100%;
        }

        &.storePlanner:after {
          content: 'gallery planner';
          top: 7px;
          right: -125px;
        }

        &.map:after {
          content: 'select map';
          top: 7px;
          right: -101px;
        }

        &.store:after {
          content: 'select gallery';
          top: 7px;
          right: -115px;
        }

        &:hover:after {
          display: block;
        }

        &.icon-pin-location {
          height: 35px;
          &:before {
            @include background-image(
              '../../assets/images/icons/png/pin-location-light.png',
              28,
              28
            );
          }
        }
        &.icon-real-estate-dimensions-plan {
          &:before {
            @include background-image(
              '../../assets/images/icons/png/real-estate-dimensions-plan-light.png',
              24,
              24
            );
          }
          margin-left: 2px;
        }
        &.active {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &__subMenuWrapper {
    width: 320px;
    height: 100%;
    padding: 0 15px 15px 15px;
    -webkit-box-shadow: 13px 0px 15px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 13px 0px 15px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 13px 0px 15px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 50px;
    background: #ffffff;
    transition: all ease 300ms;
    z-index: 1;

    &.hidden {
      left: -260px;
    }

    .sidebar__content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      &--hiddenBtn {
        position: absolute;
        width: 20px;
        height: 70px;
        background: #ffffff;
        right: -25px;
        top: 50%;
        margin-top: -5px;
        padding: 0;
        border: none;
        cursor: pointer;
        &.hidden:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-left: 0;
          width: 0;
          height: 0;

          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-right: 7px solid #000000;
        }
        &.hidden.active:before {
          border-right: 0;
          border-left: 7px solid #000000;
          margin-left: 8px;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-button:start:decrement,
      ::-webkit-scrollbar-button:end:increment {
        display: none !important;
      }

      ::-webkit-scrollbar-thumb:vertical {
        background-color: rgba(0, 0, 0, 0.3);
        height: 10px !important;
        border-radius: 6px;
      }
    }

    &--search {
      position: relative;
      .form {
        height: 35px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 16px;
        border-bottom: 1px solid #000000;
        padding-top: 5px;

        .btnBack {
          position: absolute;
          color: #000000;
          font-family: $font-proxima-nova-regular;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 17px;
          top: 15px;
          left: 0;
          text-transform: uppercase;
          text-decoration: none;
          padding-left: 15px;
          display: none;
          cursor: pointer;
          background: none;
          border: none;
          &:focus {
            outline: none;
          }
          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 7px solid #000000;
            position: absolute;
            top: 1px;
            left: 0;
          }
          &.hidden {
            display: none !important;
          }
        }

        input {
          border: none;
          color: #000000;
          font-family: $font-proxima-nova-regular;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 17px;
          text-transform: uppercase;
          width: 85%;
          transition: all ease 400ms;
          &:focus {
            outline: none;
          }
        }

        .btn {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0;
          cursor: pointer;
          border: none;
          background: none;
          &:focus,
          &:hover {
            background: none;
            outline: none;
          }
          &:before {
            @include background-image(
              '../../assets/images/icons/png/search.png',
              19,
              19
            );
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -10px;
          }

          &.hidden {
            display: none;
          }
        }

        &.hidden {
          border-bottom: none;
          .btnBack {
            display: block;
          }
          input {
            display: none;
          }
        }
      }

      .result {
        margin-bottom: 10px;
        > span {
          color: #000000;
          font-family: $font-minion-pro-regular;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2px;
          line-height: 34px;
          text-transform: uppercase;
          display: block;
        }
        .breadcrumb {
          span {
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.79px;
            line-height: 13px;
            text-transform: uppercase;
          }
        }
        &.small {
          width: 100%;
          max-width: 190px;
          margin-top: -50px;
        }
        &.hidden {
          display: none;
        }
      }

      &.hidden {
        display: none;
      }
    }

    &--select {
      width: 100%;
      z-index: 2;
      margin-bottom: 20px;
      position: relative;
      border-top: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      padding: 3px 0;
      position: relative;

      &:after {
        content: '';
        vertical-align: middle;
        margin-right: 10px;
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -3px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid black;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -3px;
      }

      select {
        background: transparent;
        width: 100%;
        display: block;
        color: #000000;
        font-family: $font-proxima-nova-regular;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.79px;
        line-height: 13px;
        text-transform: uppercase;
        padding: 5px 0;
        border: 0;
        border-radius: 0;
        -webkit-appearance: none;
        outline: none;
        position: relative;
        &:focus {
          outline: none;
        }
      }
    }

    &--mapsContent {
      position: relative;
      overflow-y: auto;
      height: calc(100% - 50px);
      padding-right: 15px;
      padding-bottom: 40px;

      .createCustomMap {
        height: 163px;
        width: 100%;
        max-width: 281px;
        border: 1px solid #000000;
        display: block;
        text-decoration: none;
        margin-bottom: 14px;

        &:hover {
          border: 2px solid #000000;
        }

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 61px;
            height: 61px;
          }
          span {
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.79px;
            line-height: 13px;
            text-transform: uppercase;
            margin-top: 10px;
          }
        }
      }

      .item {
        position: relative;
        margin-bottom: 25px;
        span {
          display: block;
          img {
            cursor: pointer;
            border: 1px solid #000000;
          }
        }
        .selected {
          border: 4px solid black;
        }

        .infoWrapper {
          margin-top: 5px;
          position: relative;
          overflow: hidden;
          .info {
            float: left;
            width: 150px;
            .nameItem {
              color: #000000;
              font-family: $font-proxima-nova-regular;
              font-size: 11px;
              font-weight: 800;
              letter-spacing: 1px;
              line-height: 13px;
              text-transform: uppercase;
              margin: 0;
              display: block;
              cursor: pointer;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .localItem {
              color: #000000;
              width: 100%;
              text-overflow: ellipsis;
              font-family: $font-proxima-nova-regular;
              font-size: 10px;
              font-weight: 500;
              line-height: 16px;
              margin: 0 0 5px 0;
              display: block;
              text-transform: uppercase;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .createdByItem,
            .createdOnItem,
            .lastUpdatedItem {
              color: #000000;
              font-family: $font-proxima-nova-regular;
              font-size: 10px;
              letter-spacing: 1px;
              line-height: 13px;
              margin: 0;
              display: block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              small {
                font-size: 11px;
                font-weight: normal;
                text-transform: capitalize;
              }
            }
          }

          .editorsItem {
            float: right;
            width: 100px;
            position: relative;
            color: #000000;
            font-family: 'Proxima Nova';
            font-size: 10px;
            font-weight: normal;
            letter-spacing: 1px;
            line-height: 13px;
            margin: 0;
            display: block;
            overflow: hidden;
            height: 65px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            strong {
              text-transform: uppercase;
              display: block;
            }
            p {
              margin: 0;
            }
          }
        }
        .editBtn {
          background: none;
          border: none;
          position: absolute;
          top: 40px;
          right: 5px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          &:before {
            @include background-image(
              '../../assets/images/icons/png/pencil.png',
              16,
              16
            );
          }
        }
        .deleteBtn {
          background: none;
          border: none;
          position: absolute;
          top: 10px;
          right: 5px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          &:before {
            @include background-image(
              '../../assets/images/icons/png/bin.png',
              16,
              16
            );
          }
        }
      }
    }

    &--allElementsContent {
      position: relative;
      overflow-y: auto;
      height: calc(100% - 50px);
      .contentWrapper {
        position: relative;
        overflow: hidden;
        .item {
          width: 125px;
          float: left;
          margin-bottom: 15px;
          cursor: pointer;

          &:nth-child(odd) {
            margin-right: 15px;
          }

          img {
            margin-bottom: 3px;
            border: 3px solid transparent;
            &.selected {
              border: 3px solid black;
            }
          }
          .description {
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.79px;
            line-height: 13px;
            text-transform: uppercase;
            height: 50px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }

    &--material {
      border-top: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      padding: 7px 0;

      .item {
        width: 100%;
        max-width: calc(100% / 5 - 2%);
        margin: 5px 1% 5px 1%;
        float: left;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
        .selected {
          border: 2px solid black;
        }
        span {
          display: block;
          color: #000000;
          font-family: $font-proxima-nova-regular;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          margin-top: 3px;
        }
        &:nth-child(5n + 1) {
          margin-left: 0;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }

    &--option {
      border-bottom: 1px solid #c7c7c7;
      padding: 7px 0;

      .optionContainer {
        display: flex;
        flex-wrap: wrap;

        .item {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 0%;
          margin-right: 10px;

          button {
            min-width: 75px;
            max-width: 150px;
            height: 50px;
            text-decoration: none;
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #c7c7c7;
            border-radius: 3px;
            text-align: center;
            margin: 5px 2% 5px 2%;
            cursor: pointer;
            background-color: white;
            span {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
            }
            &:focus,
            &:hover {
              outline: none;
            }
            &:nth-child(3n + 1) {
              margin-left: 0;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
            &.active {
              background: #000000;
              color: #ffffff;
            }
            &.disabled {
              background: #d3d3d3;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    &--infoContent {
      padding-right: 20px;
      padding-bottom: 40px;
      margin: 20px 0 0 0;

      .info {
        .content {
          p {
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 12px;
            line-height: 18px;
          }
        }
        .details {
          h3 {
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            text-transform: uppercase;
          }
          ul {
            list-style: none;
            padding-left: 40px;
            li {
              color: #000000;
              font-family: $font-proxima-nova-regular;
              font-size: 12px;
              line-height: 18px;
              position: relative;
              &:before {
                content: '';
                height: 3px;
                width: 3px;
                border-radius: 100%;
                background: #000000;
                position: absolute;
                top: 8px;
                left: -30px;
              }
            }
          }
        }
      }
    }

    &--items {
      overflow-y: auto;
      height: calc(100% - 50px);
      @media (max-height: 992px) {
        padding-right: 20px;
      }
      .item {
        display: block;
        background: #ececec;
        color: #000000;
        font-family: $font-proxima-nova-regular;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 20px 20px 20px 20px;
        margin-bottom: 10px;
        position: relative;
        transition: all ease 50ms;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -12px;
          left: 20px;
        }
        &.icon-sofa-double-modern:before {
          @include background-image(
            '../../assets/images/icons/png/sofa-double-modern.png',
            24,
            24
          );
        }
        &.icon-restaurant-eating-set:before {
          @include background-image(
            '../../assets/images/icons/png/restaurant-eating-set.png',
            24,
            24
          );
        }
        &.icon-bed-double:before {
          @include background-image(
            '../../assets/images/icons/png/bed-double.png',
            24,
            24
          );
        }
        &.icon-bathroom-tub-towel:before {
          @include background-image(
            '../../assets/images/icons/png/bathroom-tub-towel.png',
            24,
            24
          );
        }
        &.icon-ceiling-ball-chandelier-retro:before {
          @include background-image(
            '../../assets/images/icons/png/ceiling-ball-chandelier-retro.png',
            24,
            24
          );
        }
        &.icon-material-fabric:before {
          @include background-image(
            '../../assets/images/icons/png/material-fabric.png',
            24,
            24
          );
        }
        &.icon-cat-yarn:before {
          @include background-image(
            '../../assets/images/icons/png/cat-yarn.png',
            24,
            24
          );
        }
        &.icon-window:before {
          @include background-image(
            '../../assets/images/icons/png/window.png',
            24,
            24
          );
        }
        &.icon-hardware:before {
          @include background-image(
            '../../assets/images/icons/png/volume.png',
            26,
            26
          );
        }
        &.icon-decoration-cherry-blossom-vase:before {
          @include background-image(
            '../../assets/images/icons/png/decoration-cherry-blossom-vase.png',
            24,
            24
          );
        }
        &.icon-museum-painting:before {
          @include background-image(
            '../../assets/images/icons/png/museum-painting.png',
            24,
            24
          );
        }
        &.icon-gift-box:before {
          @include background-image(
            '../../assets/images/icons/png/gift-box.png',
            24,
            24
          );
        }
        &.icon-table-restaurant-beach:before {
          @include background-image(
            '../../assets/images/icons/png/table-restaurant-beach.png',
            24,
            24
          );
        }

        &.subItem {
          padding-left: 20px;
          padding-right: 30px;
          &:after {
            content: '';
            display: none;
            vertical-align: middle;
            margin-right: 10px;
            width: 0;
            height: 0;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -3px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid rgb(0, 0, 0);
          }
          &:hover:after {
            display: inline-block;
          }
          &.active:after {
            border-top: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid black;
            display: inline-block !important;
          }
        }
      }

      .item-wrapper {
        background: #ececec;
        .options {
          padding: 0 20px 20px 40px;
          margin-top: -20px;
          margin-bottom: 10px;
          display: none;
          &.show {
            display: block;
          }
          span {
            display: block;
            background: #ececec;
            color: #000000;
            font-family: $font-proxima-nova-regular;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 22px;
            text-decoration: none;
            position: relative;
            cursor: pointer;
          }
        }
      }
    }
  }
}
