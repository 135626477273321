.header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ffffff;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  
  &__logoWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    
    &--logo {
      width: auto;
      height: 30px;
      margin-right: 20px;
    }
    &--store {
      position: relative;
      padding-left: 20px;
      width: 300px;
      &:after {
        content: ('');
        position: absolute;
        width: 1px;
        height: 100%;
        left: 0;
        top: 0;
        background: #ffffff;
      }
      h1 {
        color: #ffffff;
        font-family: $font-minion-pro-regular;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.89px;
        line-height: 19px;
        text-transform: uppercase;
        display: block;
        margin: 0;
      }
      h3 {
        color: #ffffff;
        font-family: $font-proxima-nova-regular;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.79px;
        line-height: 13px;
        margin: 0;
      }
    }
  }
  &__buttonWrapper {
    .btn {
      color: #ffffff;
      font-family: $font-proxima-nova-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 100%;
      text-transform: uppercase;
      display: inline-block;
      text-decoration: none;
      padding: 10px 20px;
      border: 1px solid #ffffff;
      background: transparent;
      position: relative;
      transition: all ease 300ms;
      margin-left: 20px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 15px;
      }
      &:hover {
        background: #ffffff;
        color: #000000;
      }
      &.btn.active {
        border-bottom: 1px solid white;
        padding: 5px 0;
      }
      &.btn.active:hover {
        padding: 10px 15px;
      }

      &.btnExit {
        padding: 10px 15px 10px 45px;
        &:before {
          @include background-image('../../assets/images/icons/png/close-light.png', 16, 16);
          margin-top: -8px;
        }
        &:hover {
          &:before {
            @include background-image('../../assets/images/icons/png/close.png', 16, 16);
          }
        }
      }
      &.btnSave {
        padding: 10px 15px 10px 45px;
        &:before {
          @include background-image('../../assets/images/icons/png/floppy-disk-light.png', 16, 16);
          margin-top: -8px;
        }
        &:hover {
          &.btn:before {
            @include background-image('../../assets/images/icons/png/floppy-disk.png', 16, 16);
          }
        }
      }
      &.btn__link {
        padding: 10px 15px;
        border: 0;
        &:hover {
          background: #ffffff;
          color: #000000;
        }
      }
    }
  }
}
