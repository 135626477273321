.dPadWrapper {
  position: absolute;
  right: 40px;
  bottom: 180px;

  .btnController {
    border: 1px solid #000000;
    height: 36px;
    width: 36px;
    cursor: pointer;
    margin: 5px;
    background-color: #000000;
    color: white;
    padding: 5px;

    &.dpadUp {
      bottom: 130px;
      position: absolute;
      right: 26px;
    }

    &.dpadLeft {
      bottom: 90px;
      position: absolute;
      right: 60px;
    }

    &.dpadRight {
      bottom: 90px;
      right: -10px;
      position: absolute;
    }

    &.dpadDown {
      position: absolute;
      right: 26px;
      bottom: 50px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
