.dialogBox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);

  &.blur-heavy {
    backdrop-filter: blur(6px);
  }

  &.newversion {
    backdrop-filter: blur(6px);
    z-index: 1000;
  }

  &.confirmoverwrite {
    backdrop-filter: blur(1px);
  }

  &.light {
    background: rgba(255, 255, 255, 0.3);
  }

  &__wrapper {
    width: 100%;
    max-width: 900px;
    padding: 17px;
    background: #000000;
    position: relative;

    &.confirmoverwrite {
      border: 1px solid white;
      max-width: 350px;
      max-height: 240px;
    }

    &.offlineWarning {
      border: 1px solid white;
      max-width: 350px;
      max-height: 300px;
    }

    &.intro {
      max-width: 850px;
      max-height: 575px;
    }

    &.savingDialog {
      height: 575px;
    }

    &--header {
      display: flex;
      justify-content: flex-end;
      .store {
        position: relative;
        padding-left: 20px;
        &:after {
          @include background-image(
            '../../assets/images/icons/png/pin-location-light.png',
            23,
            23
          );
          position: absolute;
          top: 3px;
          left: -15px;
        }
        h1 {
          color: #ffffff;
          font-family: 'Minion Pro';
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 2.14px;
          line-height: 18px;
          margin: 0;
          text-transform: uppercase;
        }
        h3 {
          color: #ffffff;
          font-family: 'Proxima Nova' sans-serif;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.71px;
          line-height: 12px;
          margin: 0;
          text-transform: uppercase;
        }
      }

      // Finish dialogbox
      .btnClose {
        height: 23px;
        width: 23px;
        position: relative;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        &:focus {
          outline: none;
        }
        &:after {
          @include background-image(
            '../../assets/images/icons/png/close-light.png',
            23,
            23
          );
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      // Saved dialogbox
      &.--back {
        justify-content: space-between;
        .backBtn {
          color: #ffffff;
          font-family: 'Proxima Nova' sans-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 2px;
          line-height: 17px;
          text-transform: uppercase;
          text-decoration: none;
          position: relative;
          padding: 0 0 0 20px;
          background: none;
          cursor: pointer;
          border: none;
          &:focus {
            outline: none;
          }
          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 7px solid #ffffff;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }
      }
    }

    &--content {
      text-align: center;
      color: #ffffff;
      padding: 55px 0 35px 0;
      position: relative;
      width: 100%;
      overflow: hidden;
      &:after {
        content: '';
        height: 1px;
        width: 80px;
        background: #ffffff;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -40px;
        display: block;
      }
      h3 {
        font-family: 'Proxima Nova' sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 17px;
        text-transform: uppercase;
        margin: 0;
      }
      h2 {
        font-family: 'Minion Pro';
        font-size: 32px;
        letter-spacing: 2px;
        font-weight: 500;
        line-height: 46px;
        text-transform: uppercase;
        margin: 2px 0;
      }
    }

    &--buttons {
      display: flex;
      justify-content: center;
      padding: 50px 0 70px 0;

      &.confirmoverwrite {
        padding: 10px 0 10px 0;
      }

      @media (max-width: 800px) {
        padding: 50px 20px 0 0;
      }

      @media (max-height: 640px) {
        padding: 50px 20px 0 0;
      }

      button {
        text-decoration: none;
        text-align: center;
        width: 174px;
        height: 174px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        margin: 0 30px;
        transition: all ease 150ms;
        cursor: pointer;
        background: none;
        &:focus {
          outline: none;
        }
        @media (max-width: 800px) {
          margin: 0 15px;
        }

        @media (max-height: 800px) {
          margin: 0 15px;
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.inative {
          opacity: 0.3;
          pointer-events: none;
        }
        &:hover {
          transform: scale(1.05);
        }
        img {
          margin-bottom: 20px;
          height: 60px;
          width: auto;
        }
        span {
          font-family: 'Proxima Nova' sans-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 2px;
          line-height: 17px;
          text-transform: uppercase;
          text-decoration: none;
          color: #ffffff;
          margin: 0;
        }
      }
    }

    &--plans {
      width: 100%;
      margin-top: 50px;

      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-button:start:decrement,
      ::-webkit-scrollbar-button:end:increment {
        display: none !important;
      }

      ::-webkit-scrollbar-thumb:vertical {
        background-color: rgb(255, 255, 255);
        height: 10px !important;
      }

      .loadingWrapper {
        cursor: wait;
      }

      .wrapper {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        max-height: 280px;
        position: relative;

        @media (max-width: 800px) {
          max-height: 220px;
        }

        @media (max-height: 640px) {
          max-height: 220px;
        }

        .content {
          width: 100%;
          max-width: 740px;
          margin: 0 auto;
          display: flex;
          align-content: stretch;
          flex-wrap: wrap;
          padding-bottom: 80px;

          .planItemLoading {
            background-color: black;
            color: white;
          }

          .planItemNotLoading {
            background-color: black;
            color: white;

            &:hover {
              background-color: white;
              color: black;
              cursor: pointer;
            }
          }

          .selectedPlanLoading {
            background-color: white !important;
            color: black !important;
          }

          .planItem {
            position: relative;
            height: 182px;
            width: 353px;
            border: 1px solid #ffffff;
            margin-bottom: 15px;

            .MuiCircularProgress-root {
              position: absolute;
              margin-top: 19%;
              margin-left: 44%;
            }

            &:nth-child(2n + 1) {
              margin-right: 20px;
            }

            @media (max-width: 920px) {
              margin: 0 auto 20px auto !important;
            }

            .thumbnail {
              float: left;
              cursor: pointer;

              .floorPlanImg {
                height: 180px;
              }
            }
            .info {
              float: left;
              width: 232px;
              padding: 10px 0;
              .infoWrapper {
                span {
                  display: block;

                  &.nameItem {
                    padding: 0 10px;
                    font-family: 'Minion Pro';
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    line-height: 18px;
                    margin: 0;
                    display: block;
                    cursor: pointer;
                    width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  &.localItem {
                    padding: 0 10px;
                    width: 180px;
                    text-overflow: ellipsis;
                    font-family: 'Proxima Nova' sans-serif;
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    line-height: 16px;
                    margin: 0 0 15px 0;
                    display: block;
                    text-transform: uppercase;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  &.createdByItem,
                  &.createdOnItem,
                  &.lastUpdatedItem {
                    padding: 0 10px;
                    font-family: 'Proxima Nova' sans-serif;
                    font-size: 11px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 16px;
                    text-transform: uppercase;
                    margin: 0;
                    display: block;
                    width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    small {
                      font-size: 11px;
                      font-weight: normal;
                      text-transform: capitalize;
                    }
                  }
                  &.lastUpdatedItem {
                    padding-bottom: 10px;
                  }
                  &.editorsItem {
                    position: relative;
                    padding: 10px;
                    font-family: 'Proxima Nova' sans-serif;
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    line-height: 13px;
                    margin: 0;
                    display: block;
                    overflow: hidden;
                    height: 50px;
                    border-top: 1px solid #ffffff;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    strong {
                      text-transform: uppercase;
                      display: block;
                    }
                    p {
                      margin: 0;
                      padding-right: 20px;
                    }
                    .editBtn {
                      background: none;
                      border: none;
                      position: absolute;
                      top: 10px;
                      right: 5px;
                      cursor: pointer;
                      &:focus {
                        outline: none;
                      }
                      &:before {
                        @include background-image(
                          '../../assets/images/icons/png/pencil-light.png',
                          16,
                          16
                        );
                      }
                    }
                  }
                }
              }
            }
            .deleteBtn {
              background: none;
              border: none;
              position: absolute;
              top: 10px;
              right: 5px;
              cursor: pointer;
              &:focus {
                outline: none;
              }
              &:before {
                @include background-image(
                  '../../assets/images/icons/png/bin-light.png',
                  16,
                  16
                );
              }
            }
          }
        }
      }
    }
    &--planName {
      .form {
        width: 100%;
        max-width: 375px;
        margin: 70px auto 80px auto;

        @media (max-width: 800px) {
          margin: 70px auto 45px auto;
        }

        @media (max-height: 640px) {
          margin: 35px auto 45px auto;
        }

        .name {
          display: block;
          width: 100%;
          background: transparent;
          border: 1px solid #ffffff;
          color: #ffffff;
          font-family: 'Proxima Nova' sans-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1px;
          margin: 0;
          padding: 15px 20px;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #ffffff;
            opacity: 0.9;
            text-transform: uppercase;
          }

          &:-ms-input-placeholder {
            color: #ffffff;
            opacity: 0.9;
            text-transform: uppercase;
          }

          &::-ms-input-placeholder {
            color: #ffffff;
            opacity: 0.9;
            text-transform: uppercase;
          }
        }

        .btnSave {
          border: none;
          background: #ffffff;
          padding: 12px 20px 10px 35px;
          color: #000000;
          font-family: 'Proxima Nova' sans-serif;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 100%;
          width: 83px;
          text-transform: uppercase;
          margin: 40px auto 0 auto;
          display: block;
          position: relative;
          text-decoration: none;
          cursor: pointer;
          @media (max-width: 800px) {
            margin: 35px auto 0 auto;
          }

          @media (max-height: 640px) {
            margin: 35px auto 0 auto;
          }

          &:focus {
            outline: none;
          }
          &:before {
            @include background-image(
              '../../assets/images/icons/png/floppy-disk.png',
              16,
              16
            );
            position: absolute;
            left: 10px;
            top: 9px;
          }
        }
      }
    }
  }

  .error {
    color: red;
    font-family: 'Proxima Nova';
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  .large-logo {
    margin: 10px;
  }

  .loadingModal {
    margin-bottom: 20px;
  }
}
