.colorPickerContainer {
  position: absolute;
  height: 120px;
  width: 194px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 5px;
  text-transform: uppercase;

  .btnClose {
    height: 28px;
    width: 28px;
    position: absolute;
    cursor: pointer;
    background-color: #000000;
    border-radius: 50%;
    border: none;
    padding: 0;
    left: -10px;
    top: -10px;
    &:focus {
      outline: none;
    }

    &:after {
      @include background-image(
        '../../assets/images/icons/png/close-light.png',
        16,
        16
      );
      margin-top: 3px;
    }
  }

  .label {
    height: 12px;
    width: 100%;
    color: #ffffff;
    font-family: 'Proxima Nova';
    font-size: 10px;
    font-weight: 750;
    letter-spacing: 1px;
    line-height: 12px;
  }

  .line {
    margin: 5px 0;
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #ffffff;
  }

  .color-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .color {
      border-radius: 50%;
      margin: 4px;
      opacity: none !important;

      &:hover {
        cursor: pointer;
      }

      &.oval {
        box-sizing: border-box;
        height: 18px;
        width: 18px;
        border: 1px solid #ffffff;
      }
    }
  }

  .manualSetColor {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;

    input {
      width: 66px;
      margin-right: 10px;
    }

    button:hover {
      cursor: pointer;
    }
  }
}
.errorMsg {
  color: red;
  text-align: left;
  text-transform: none;
  margin-top: 2px;
}
