html,
body,
.grid-container {
  height: 100%;
  margin: 0;
}

.grid-container * {
  border: 1px solid red;
  position: relative;
}

.grid-container *:after {
  content: attr(class);
  position: absolute;
  top: 0;
  left: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . . . . . . . . . .";
}

@media all and (-ms-high-contrast: none) {
  .grid-container {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 1fr;
  }
}
